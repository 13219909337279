import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>В разработке</h1>
        <div style={{ maxWidth: `300px`, marginBottom: `1.45rem`, display: `block`, marginLeft: `auto`, marginRight: `auto`}}>
      <Image />
    </div>
    <p>Under construction</p>
    <a href="mailto:info@pantax.co.il">Связаться с нами</a> <br />
  </Layout>
)

export default IndexPage
